<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">工单管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">工单列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="工单编号" class="searchboxItem ci-full">
              <span class="itemLabel">工单编号:</span>
              <el-input
                v-model="orderNo"
                clearable
                placeholder="请输入工单编号"
                size="small"
              ></el-input>
            </div>
            <div title="工单内容" class="searchboxItem ci-full">
              <span class="itemLabel">工单内容:</span>
              <el-input
                v-model="chatContent"
                clearable
                placeholder="请输入工单内容"
                size="small"
              ></el-input>
            </div>
            <div title="工单状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">工单状态:</span>
              <el-select
                v-model="orderState"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in workorderState"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="提交时间" class="searchboxItem ci-full">
              <span class="itemLabel">提交时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="createTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div class="df" style="padding-left:10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button type="primary" class="bgc-bv" round @click="addModel()"
                >新建工单</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="removeSearch()"
                >清空搜索条件</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="工单编号"
                align="left"
                show-overflow-tooltip
                prop="orderNo"
                min-width="150px"
                fixed
              >
                <template slot-scope="scope">
                  <div style="display:flex;align-items: center;">
                    <span
                      style="width:5px;height:5px;background:red;border-radius:50%"
                      v-if="scope.row.orderToView"
                    ></span>
                    {{ scope.row.orderNo }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="内容"
                align="left"
                show-overflow-tooltip
                prop="chatContent"
                min-width="220px"
              />
              <el-table-column
                label="分类"
                align="left"
                show-overflow-tooltip
                prop="orderQuestionType"
                min-width="120px"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "WORK_ORDER_QUESTION_TYPE",
                      scope.row.orderQuestionType || "--"
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="提交时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                min-width="150px"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>

              <el-table-column
                label="工单状态"
                align="left"
                show-overflow-tooltip
                prop="orderState"
                min-width="120px"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "WORK_ORDER_STATE",
                      scope.row.orderState || "--"
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="工单类型"
                align="left"
                show-overflow-tooltip
                prop="orderType"
                min-width="120px"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "WORK_ORDER_TYPE",
                      scope.row.orderType || "--"
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="180px"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="lookOrder(scope.row.orderId, scope.row.chatId,scope.row.orderState)"
                    >查看</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                     :disabled="scope.row.orderState != '10'"
                    @click="deleteOrder(scope.row.orderId)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
      <el-dialog
        title="查看工单"
        :visible.sync="toVoidLoding"
        top="1%"
        width="60%"
        @close="toVoidClose"
        class="toVoidLoding"
      >
        <div style="background:#c3c3c3;padding:5px 0">
          <div style="display: flex;justify-content: space-around;">
            <span>提出单位名称:{{ tovoidData.orderCompanyName }}</span>
            <span
              >分类:{{
                $setDictionary(
                  "WORK_ORDER_QUESTION_TYPE",
                  tovoidData.orderQuestionType
                )
              }}</span
            >
            <span
              >类型:{{
                $setDictionary("WORK_ORDER_TYPE", tovoidData.orderType)
              }}</span
            >
            <span
              >状态:{{
                $setDictionary("WORK_ORDER_STATE", tovoidData.orderState)
              }}</span
            >
            <span>工单编号:{{ tovoidData.orderNo }}</span>
          </div>
        </div>
        <div style="height:400px">
          <div class="ovy-a">
            <div
              v-for="(item, index) in tovoidData.edoWorkChatTmpBOList"
              :key="index"
              style="margin-top:15px"
              :style="item.roleId == 1 ? 'text-align:left' : 'text-align:right'"
            >
              <h3>{{ item.fullName }} <span  style="font-size:.85rem;font-weight: 100;padding-left:.5rem;">{{ item.createTime | moment }}</span></h3>
              <p>{{ item.chatContent }}</p>
              <p
                v-for="(item1, index) in item.tmpFileKeys"
                :key="index"
                style="color:#409EFF;"
              >
                {{ item1.name }}
                <el-button
                  type="text"
                  style="padding:0"
                  @click="jumpDown(index, item1.url)"
                  >下载</el-button
                >
              </p>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footers">
          <el-input
           v-show="lorderState!='30'"
            v-model="chatContents"
            clearable
            placeholder="请输入"
            size="small"
            type="textarea"
          ></el-input>
           <div style="display:flex;align-items: center;">
          <el-upload
           v-show="lorderState!='30'"
            class="upload-demo upload-btn"
            :action="actionUrl"
            :on-error="handleError"
            :on-success="handleSuccess"
            :on-change="uploadChange"
            multiple
            :limit="5"
            :show-file-list="false"
            :auto-upload="false"
          >
           <el-button class="bgc-bv" size="mini">上传附件</el-button>
          </el-upload>
          <span  v-show="lorderState!='30'" style="color:#f46173;margin:15px 0 0 10px"
            >支持上传word、excel、文本、图片、pdf等，文件大小不超过3M，最多上传5个文件。</span
          >
           </div>
          <div style="display:flex;flex-direction: column;">
            <span
              style="color:#409EFF;text-align:left"
              v-for="(item, index) in NameList"
              :key="index"
            >
              {{ item.name }}
              <a @click="reomveExl(index)" style="color: red;margin:0 15px"
                >删除</a
              >
            </span>
          </div>
          <el-button
           v-show="lorderState!='30'"
            type="primary"
            size="small"
            class="bgc-bv"
            round
            @click="toVoidDetermine"
            >发送</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "workOrder/workOrderEdit",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      orderNo: "", //工单编号
      chatContent: "",
      orderState: "",
      createTime: "",
      workorderState: [],
      toVoidLoding: false,
      tovoidData: {},
      chatContents: "",
      NameList:[],
chatFileKeys:[],
    };
  },
  watch: {},
  created() {
    this.getdataList();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
  },
  methods: {
    //类型
    getdataList() {
      const workorderstate = this.$setDictionary("WORK_ORDER_STATE", "list");
      const workorderStatelist = [];
      for (const key in workorderstate) {
        workorderStatelist.push({
          value: key,
          label: workorderstate[key],
        });
      }
      this.workorderState = workorderStatelist;
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.orderNo) {
        params.orderNo = this.orderNo;
      }
      if (this.chatContent) {
        params.chatContent = this.chatContent;
      }
      if (this.orderState) {
        params.orderState = this.orderState;
      }
      if (this.createTime) {
        params.createTimeStart = this.createTime[0];
        params.createTimeEnd = this.createTime[1];
      }
      this.doFetch({
        url: "/repairOrder/work/order/institutions/pageList/",
        params,
        pageNum,
      });
    },
    //编辑
    editModel(contractId) {
      this.$router.push({
        path: "/web/financialSettlement/contractManagementAdd",
        query: {
          contractId,
        },
      });
    },
    //删除
    deleteOrder(orderId) {
      this.doDel({
        url: "/repairOrder/work/order/delete",
        msg: "确定删除工单吗？",
        ps: {
          type: "post",
          data: { orderId },
        },
      });
    },
    //新增工单
    addModel() {
      this.$router.push({
        path: "/web/workOrder/workOrderEditJg",
      });
    },

    lookOrder(orderId, chatId,orderState) {
      this.$post("/repairOrder/work/order/toView", { orderId })
        .then((ret) => {
          if (ret.status == "0") {
            this.toVoidLoding = true;
            this.tovoidData = ret.data;
            this.chatId = chatId;
            this.orderId = orderId;
             this.lorderState = orderState
          }
        })
        .catch((err) => {
          return;
        });
    },
    toVoidClose() {
      this.toVoidLoding = false;
      this.getData();
    },
    //弹框确定
    toVoidDetermine() {
         if(this.chatContents.trim() == '') {
            this.$message({
                message:'发送消息不能为空',
                type:'error'
            })
            return
        } else {
      const params = {
        chatContent: this.chatContents,
        chatId: this.chatId,
        orderId: this.orderId,
        chatFileKeys: this.chatFileKeys,
      };
      this.$post("/repairOrder/work/chat/insert", params).then((ret) => {
        this.lookOrder(this.orderId, this.chatId);
        this.chatContents = "";
        this.NameList = [];
          this.fileList = [];
          this.chatFileKeys = []
      });
        }
    },
    uploadChange(file) {
      let size = file.size / 1024 / 1024;
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (size > 3) {
        this.$message.error("文件大小不能超过3M");
        return;
      }

      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.NameList.push({
            name: file.name,
            fileKey: result.data.fileKey,
          });
          this.chatFileKeys.push(result.data.fileKey+'_' + file.name);
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    reomveExl(index) {
      this.NameList.splice(index, 1);
      this.chatFileKeys.splice(index, 1);
      this.$forceUpdate();
    },
    jumpDown(index, url) {
      window.open(url);
    },
     removeSearch() {
      this.orderNo = "";
      this.chatContent = "";
      this.orderState = "";
      this.createTime = "";
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 2) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function(route) {
        if (route.query.refresh == true) {
          this.getData();
        }
        // this.activeName = route.params.refName;
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
// .dialog-footers {
//   .el-textarea__inner {
//     resize: none;
//     min-height: 100px;
//   }
// }
</style>
<style lang="less">
.dialog-footers {
  .el-textarea__inner {
    resize: none;
    min-height: 100px !important;
  }
}
.upload-btn {
  width: 5rem;
  height: 28px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
</style>
